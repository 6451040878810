import { Hub } from 'aws-amplify/utils'
import type { GraphQLSubscription } from '@aws-amplify/api'
import type { ConnectionState } from 'aws-amplify/api'
import { generateClient } from 'aws-amplify/api'
import { CONNECTION_STATE_CHANGE } from '@aws-amplify/api-graphql'
import type { Subscription } from 'rxjs'
import type { OnInventorySubscription } from '../../graphql/config/generated'
import { OnInventoryDocument } from '../../graphql/config/generated'

// ConsoleLogger.LOG_LEVEL = 'DEBUG'

export const useSubscriptions = defineStore('useSubscriptions', () => {
  const currentEntity = useCurrentEntity()
  const currentUser = useCurrentUser()

  const subscriptionsInventory = ref<Map<string, Subscription>>(new Map())

  watchEffect(() => {
    if (!currentEntity.entity?.warehouses)
      return
    const warehouseIds = currentEntity.entity.warehouses.map(warehouse => warehouse.warehouseId)
    // add new subscriptions
    warehouseIds.forEach((id) => {
      if (!subscriptionsInventory.value.has(id))
        subscriptionsInventory.value.set(id, createSubscriptionInventory(id))
    })
    // remove old subscriptions
    subscriptionsInventory.value.forEach((subscription, warehouseId) => {
      if (!warehouseIds.find(id => id === warehouseId)) {
        subscription.unsubscribe()
        subscriptionsInventory.value.delete(warehouseId)
      }
    })
  })

  function createSubscriptionInventory(warehouseId: string) {
    // if (warehouseId !== 'Entrepôt 2')
    //  return
    const client = generateClient()
    const variables = {
      warehouseId,
      entityId: currentUser.entityId,
    }
    // console.log(variables)
    const subscription = client.graphql<GraphQLSubscription<OnInventorySubscription>>({
      query: OnInventoryDocument,
      variables,
    },
    ).subscribe({
      next: ({ data }) => {
        console.log('SUB', data, variables)
        if ((data.onInventory?.errors?.length || 0) > 0)
          throw new Error(JSON.stringify(data?.onInventory?.errors))
        useQueryClientSingleton().queryClient.setQueryData(
          ['getStocks', variables],
          {
            stocks: {
              items: data.onInventory?.items || [],
            },
          },
        )
      },
      error: (error) => {
        console.error(error)
        throw new Error(error)
      },
    })
    return subscription
  }

  Hub.listen('api', (data: any) => {
    const { payload } = data
    if (payload.event === CONNECTION_STATE_CHANGE) {
      const connectionState = payload.data.connectionState as ConnectionState
      console.log(connectionState)
    }
  })

  function $reset() {
    subscriptionsInventory.value.forEach((subscription) => {
      subscription.unsubscribe()
    })
    subscriptionsInventory.value.clear()
  }

  return {
    subscriptionsInventory,
    $reset,
  }
})
