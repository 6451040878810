import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'
import { numberFormats } from '@/config/numberFormats'

export const i18n = createI18n({
  fallbackWarn: false,
  missingWarn: false,
  silentFallbackWarn: true,
  locale: 'fr',
  messages,
  numberFormats,
})
