<script setup lang="ts">
const search = useSearch()
const { t } = useI18n()
</script>

<template>
  <div class="flex flex-col">
    <div class="flex gap-x2 mb-4">
      <Tag
        v-for="(category, index) in search.categories"
        :key="index"
        :class="{

          'tag-enabled': search.getFilters(category.name).every(
            item => search.activeFilters.includes(item),
          ),
        }"
        class="tag"
        :pt="{
          root: {
            class: '!b-rd-0.1rem ',
          },
        }"
        @click="search.addFilters(...search.getFilters(category.name))"
      >
        <div class="flex gap-x-1">
          <div>{{ t(category.name) }}:</div>
          <div>{{ category.count }}</div>
        </div>
      </Tag>
    </div>
    <div class="divider w-100% h-2px" />
  </div>
</template>

<style scoped>
.tag {
  background-color: var(--gray-300);
  color: var(--gray-600);
  cursor: pointer;
}

.tag-enabled {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  cursor: pointer;
}

.divider {
  background-color: var(--gray-300);
}
</style>

<i18n lang="json">
  {
    "en": {},
    "fr": {
      "invoiceCustomer": "factures client",
      "invoiceSupplier": "factures fournisseur",
      "orderCustomer": "commandes client",
      "orderSupplier": "commandes fournisseur",
      "quotationCustomer": "devis client",
      "quotationSupplier": "devis fournisseur"
    }
  }
  </i18n>
