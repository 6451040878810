<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog'
import LogoK22 from '@/assets/logo_with_name_K22.svg?component'

// info on app
const currentEntity = useCurrentEntity()

const logoCPF = 'https://cdn.k22.fr/CPF~0000/logo_name.png'
</script>

<template>
  <div class="rounded rounded-md">
    <div class="flex-1 basis-auto">
      <TheAuthenticator>
        <nav
          class="flex flex-col max-h-100% pointer-events-auto border-none pr-2 !hover:shadow-xl"
        >
          <div class="flex flex-col overflow-y-auto">
            <div class="flex flex-items-center mb-4">
              <RouterLink v-if="currentEntity.entity" :to="{ name: '/[entityId]/' }" class="flex pa-2 align-center">
                <LogoK22 v-if=" currentEntity.entity?.entityId.substring(0, 3) === 'K22'" class="color-primary h-9" />
                <img
                  v-else
                  class="h-12"
                  :src="logoCPF"
                >
              </RouterLink>
            </div>
            <!-- Apps -->
            <TheApps class="mb-6" />
            <!-- En fonction de l'application -->
            <DrawerMenuApp />
            <!-- filtres en fonction de la page de l'application @xxx.vue -->
            <div id="filter-menu" class="overflow-y-auto">
              <slot name="filter" />
            </div>
          </div>

          <div class="flex flex-col mt-auto">
            <div class="overflow-hidden">
              <DrawerMenuParams />
            </div>
          </div>
        </nav>

        <header>
          <div class="h-14 flex items-center w-100% justify-end">
            <div class="shrink-1 basis-90% flex justify-center items-center">
              <TheSearchBar class="min-w-80 w-120 max-w-120" :icon="false" />
            </div>
            <div class="flex grow-1 shrink-0">
              <div class="flex items-center gap-x-1 ml-auto">
                <NavThemeSwitcher />
                <NavUserProfile />
              </div>
            </div>
          </div>
        </header>

        <main class="min-w-100vh">
          <slot v-if="!currentEntity.isLoadingRequiredData" />
          <div v-else class="w-100% h-100% flex justify-center items-center">
            <!-- <img src="/loading.gif" alt="loading animation" width="648"> -->
            <ProgressSpinner class="text-primary" />
          </div>
        </main>
        <Toast :base-z-index="2000" />
        <DynamicDialog
          :base-z-index="2000"
          :pt="{
            headerIcons: { class: 'ml-auto' },
          }"
        />

        <ConfirmDialog
          :draggable="false"
        >
          <template #message="slotProps">
            <div class="flex p-4 items-center gap-x-5">
              <i :class="slotProps.message.icon" class="text-4xl" />
              <p v-html="slotProps.message.message" />
            </div>
          </template>
        </ConfirmDialog>
      </TheAuthenticator>
    </div>
  </div>
</template>

<style scoped>
main {
  flex: 1 0 auto;
  max-width: 100%;
  padding-top: 56px;
  padding-left: 17.5rem;
}

header{
  color: var(--text-color);
  background-color: var(--surface-ground);
  top: 0px;
  z-index: 1004;
  transform: translateY(0%);
  position: fixed;
  left: 17.5rem;
  width: calc(100% - 17.5rem);
}

nav {
  color: var(--text-color);
  background-color: var(--surface-ground);
  left: 0px;
  z-index: 1004;
  transform: translateX(0%);
  position: fixed;
  height: calc100%;
  top: 0px;
  bottom: 0px;
  width: 17.5rem;
}
</style>
