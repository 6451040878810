import { useGetUserQuery } from '@graphql'
import { fetchUserAttributes, signOut } from 'aws-amplify/auth'
import { useAuthenticator } from '@aws-amplify/ui-vue'
import type { AuthenticatorReturnType } from '@/types/Authenticator'

export async function checkUser(to: any) {
  const authenticator: AuthenticatorReturnType = useAuthenticator()
  if (authenticator.authStatus === 'unauthenticated')
    return
  const userContext = useUserContext()
  try {
    const userInfo = await fetchUserAttributes()
    if (!userInfo.email)
      throw new Error('No email in attributes')

    userContext.email = userInfo.email
  }
  catch (error) {
    // in case the client is logged on a user removed in cognito
    await signOut()
    // no authenticated user
    return
  }

  if (to.path === '/' || to.path === '/accept-cgu')
    return

  // si pas d'entité retour sur la page de choix des entités
  if (!to.params.entityId) {
    userContext.entityId = ''
    return '/'
  }

  userContext.entityId = to.params.entityId as string

  // Test du user si existant
  const { queryClient } = useQueryClientSingleton()

  const variablesGetUser = {
    email: userContext.email,
    entityId: userContext.entityId,
  }

  // NOTE query en custom car l'integration n'est pas dispo dans le contexte mw
  if (queryClient) {
    const getUser = await queryClient.ensureQueryData({
      queryKey: useGetUserQuery.getKey(variablesGetUser),
      queryFn: useGetUserQuery.fetcher(variablesGetUser),
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    })

    if (!getUser.user)
      return '/'

    if (!getUser.entity?.entity)
      return '/'

    if (to.fullPath.includes(`/${userContext.entityId}/administration`) && getUser.user?.isAdmin === false)
      return `/${userContext.entityId}/`
  }
}
