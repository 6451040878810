<script setup lang="ts">
const { query, label } = defineProps<{
  query: string
  label: string
}>()
const search = useSearch()
</script>

<template>
  <div
    class="flex items-center cursor-pointer hover:bg-gray-2"
    @click="search.setQueryWithFocus(query)"
  >
    <Tag
      class="tag"
      :pt="{
        root: {
          class: '!b-rd-0.1rem ',
        },
      }"
    >
      {{ query }}
    </Tag>
    <div class="ml-2">
      {{ label }}
    </div>
  </div>
</template>

<style scoped>
.tag {
  background-color: var(--gray-300);
  color: var(--gray-600);
  cursor: pointer;
}
</style>
