import type { ResourcesConfig } from 'aws-amplify'

const amplify: ResourcesConfig = {
  API: {
    GraphQL: {
      defaultAuthMode: 'userPool',
      region: 'eu-west-1',
      endpoint: 'https://vaiexw6u4jeflb5vs4mhz3d6wq.appsync-api.eu-west-1.amazonaws.com/graphql',
    },
  },
  Auth: {
    Cognito: {
      allowGuestAccess: false,
      identityPoolId: 'eu-west-1:657214ee-69c2-4a83-a6a1-ba08a7e4703a',
      userPoolId: 'eu-west-1_Kca1oZ3Dx',
      userPoolClientId: '289ip0sdbrs1tmbqohp83jbnpk',
      mfa: {
        status: 'off',
      },
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: false,
      },
      signUpVerificationMethod: 'code',
      userAttributes: {
        email: { required: true },
      },
      loginWith: {
        email: true,
        phone: false,
        username: false,
      },
    },
  },
  Storage: {
    S3: {
      bucket: 'leandco-k22',
      region: 'eu-west-1',
    },
  },
}

const cookieStorage: {
  path: string
  expires: number
  sameSite: 'strict' | 'lax' | 'none'
} = {
  // (optional) - Cookie path
  path: '/',
  // (optional) - Cookie expiration in days
  expires: 365,
  // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
  sameSite: 'strict',
}

export { amplify, cookieStorage }
