import Menu from '@/config/menu/drawer'
import accounting from '@/assets/apps/Accounting.svg?component'
import billing from '@/assets/apps/Billing.svg?component'
import contacts from '@/assets/apps/Contacts.svg?component'
import logistic from '@/assets/apps/Logistic.svg?component'
import prices from '@/assets/apps/Prices.svg?component'
import products from '@/assets/apps/Products.svg?component'
import purchases from '@/assets/apps/Purchases.svg?component'
import reporting from '@/assets/apps/Reporting.svg?component'
import sales from '@/assets/apps/Sales.svg?component'
import stocks from '@/assets/apps/Stocks.svg?component'

export function useAppContext() {
  const route = useRoute()

  const currentApp = computed(() => {
    const splits = route.path.split('/')
    if (splits.length < 3 || splits[2] === '')
      return 'empty'

    return splits[2]
  })

  const menuList = computed(() => {
    switch (currentApp.value) {
      case 'logistic':
        return Menu.logistic

      case 'prices':
        return Menu.prices

      case 'administration':
        return Menu.administration

      case 'sales':
        return Menu.sales

      case 'reporting':
        return Menu.reporting

      case 'contacts':
        return Menu.contacts

      default:
        return []
    }
  })

  const menuIcon = computed(() => {
    switch (currentApp.value) {
      case 'logistic':
        return logistic

      case 'prices':
        return prices

      case 'accounting':
        return accounting

      case 'purchases':
        return purchases

      case 'contacts':
        return contacts

      case 'billing':
        return billing

      case 'products':
        return products

      case 'reporting':
        return reporting

      case 'stocks':
        return stocks

      case 'sales':
        return sales

      default:
    }
  })

  return {
    menu: menuList,
    icon: menuIcon,
    currentApp,
  }
}
