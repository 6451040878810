<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog'
import icon from '@/assets/logo.svg?component'

// use y position of scroll for app-bar
// const { y } = useWindowScroll()
const { platform } = useEnv()
const logoCPF = 'https://cdn.k22.fr/CPF~0000/logo_name.png'
</script>

<template>
  <div class="rounded rounded-md">
    <div class="flex-1 basis-auto">
      <TheAuthenticator>
        <header>
          <div class="h-14 flex items-center w-100%">
            <div class="ms-10px me-auto items-center flex">
              <component :is="icon" v-if="platform === 'K22'" class="w-9 h-100% object-contain color-primary" />
              <img
                v-else
                class="h-12"
                :src="logoCPF"
              >
            </div>

            <TheSearchBar :icon="false" class="min-w-80 w-120 max-w-120" />

            <div class="ms-auto me-10px items-center flex">
              <div class="flex align-center gap-x-1">
                <NavThemeSwitcher />
                <NavUserProfile />
              </div>
            </div>
          </div>
        </header>

        <main>
          <slot />
        </main>
        <Toast :base-z-index="2000" />
        <DynamicDialog
          :base-z-index="2000"
          :pt="{
            headerIcons: { class: 'ml-auto' },
          }"
        />
        <ConfirmDialog
          :draggable="false"
        >
          <template #message="slotProps">
            <div class="flex p-4 items-center gap-x-5">
              <i :class="slotProps.message.icon" class="text-4xl" />
              <p v-html="slotProps.message.message" />
            </div>
          </template>
        </ConfirmDialog>
      </TheAuthenticator>
    </div>
  </div>
</template>

<style scoped>
main {
  flex: 1 0 auto;
  max-width: 100%;
  padding-top: 56px;
}

header{
  color: var(--text-color);
  background-color: var(--surface-ground);
  top: 0px;
  z-index: 1004;
  transform: translateY(0%);
  position: fixed;
  left: 0px;
  width: 100%;
}
</style>
