<script setup lang="ts">
const { t } = useI18n()

const examples = [
  { label: t('example.invoice'), query: 'facture ' },
  { label: t('example.order'), query: 'commande ' },
  { label: t('example.quotation'), query: 'devis ' },
  { label: t('example.customer'), query: 'client ' },
  { label: t('example.supplier'), query: 'fournisseur ' },
  { label: t('example.ref'), query: 'ref:' },
  { label: t('example.montant'), query: 'montant:' },
]
</script>

<template>
  <div class="flex flex-col justify-center py-2">
    <div class="font-size-5 font-700 lh-6 mb-4">
      {{ t('title') }}
    </div>
    <div class="flex flex-col gap-y-2">
      <QueryOverlayFilterExample
        v-for="(example, index) in examples"
        :key="index"
        :label="example.label"
        :query="example.query"
      />
    </div>
  </div>
</template>

<style scoped>

</style>

<i18n lang="json">
  {
    "en": {},
    "fr": {
      "title": "Filtres suggérés",
      "help": "Les filtres se combinent !",
      "example": {
        "invoice": "rechercher une facture",
        "order": "rechercher une facture",
        "quotation": "rechercher un devis",
        "customer": "rechercher des contenus liés à des clients",
        "supplier": "rechercher des contenus liés à des fournisseurs",
        "ref": "une référence",
        "montant": "un contenu avec un montant spécifique"
      }
    }
  }
  </i18n>
