import { createApp } from 'vue'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import DialogService from 'primevue/dialogservice'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple'
import BadgeDirective from 'primevue/badgedirective'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { CookieStorage } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { Amplify } from 'aws-amplify'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import Toast from 'primevue/toast'
import DynamicDialog from 'primevue/dynamicdialog'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import Blank from './components/layouts/Blank.vue'
import Index from './components/layouts/Index.vue'
import Menu from './components/layouts/Menu.vue'
import Search from './components/layouts/Search.vue'
import { i18n } from './config/i18n'
import { amplify, cookieStorage } from './config/aws'
import router from '@/config/router'
import FrTranslation from '@/plugins/primevue/locales/fr.json'

// CSS Import
import 'ress/ress.css'
import 'primeicons/primeicons.css'

import 'virtual:uno.css'
import './style.css'

export const app = createApp(App)

// sentry
Sentry.init({
  app,
  dsn: 'https://945b429cbe05b7e4cd506ccbf326682a@o400627.ingest.sentry.io/4506190752448512',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/v3\.k22\.fr\//],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// layouts
app.component('BlankLayout', Blank)
app.component('IndexLayout', Index)
app.component('MenuLayout', Menu)
app.component('SearchLayout', Search)

// head
const head = createHead()
app.use(head)

// router
app.use(router)

// i18n
app.use(i18n)

// pinia
const pinia = createPinia()
app.use(pinia)

// primevue
app.use(PrimeVue, {
  locale: FrTranslation,
})
app.use(ConfirmationService)
app.use(ToastService)
app.use(DialogService)
app.component('Toast', Toast)
app.component('DynamicDialog', DynamicDialog)
app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('badge', BadgeDirective)
// tanstack
const { queryClient } = useQueryClientSingleton()
app.use(VueQueryPlugin, { queryClient })

// amplify
Amplify.configure(amplify)
const { domain } = useEnv()
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    secure: domain !== 'localhost',
    domain,
    ...cookieStorage,
  }),
)

// Error handler
app.config.errorHandler = (err, vm, info) => {
  console.error(err)
  Sentry.captureException(err, {
    extra: {
      info,
      vm,
    },
  })
}

app.mount('#app')
