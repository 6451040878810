<script setup lang="ts">
import type { DrawerMenuItem } from '@/types/DrawerMenuItem'

const mode = import.meta.env.MODE
const currentUser = useCurrentUser()
const telemetry = useTelemetry()
const { t } = useI18n()
const menu: DrawerMenuItem[] = [
  {
    title: 'Parameters',
    icon: 'i-mdi-cogs',
    to: { name: '/[entityId]/administration/' },
    url: 'administration',
  },
//   {
//     title: 'Support',
//     icon: '--mdi-lifebuoy',
//     href: 'https://themeselection.com/support/',
//     target: '_blank',
//   },
//   {
//     title: 'FAQ',
//     icon: 'i-mdi:file-document-outline',
//     href: 'https://docs.k22.fr/',
//     target: '_blank',
//   },
]
</script>

<template>
  <div v-if="mode === 'development'">
    <li v-for="(data, index) in telemetry.lastQueries" :key="index" class="list-none font-size-0.625rem">
      {{ data.query }} - {{ data.duration }}ms
    </li>
  </div>

  <TheMenu
    v-if="currentUser.user?.isAdmin"
    :title="t('parameters')"
    :menu="menu"
  />
</template>

<i18n lang="json">
  {
    "en" : {},
    "fr" : {
      "parameters": "Paramètres"
    }}
</i18n>
