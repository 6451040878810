export default function () {
  if (import.meta.env.VITE_COOKIE_DOMAIN === 'localhost')
    return 'http://localhost:5173/'

  // test platform
  if (import.meta.env.VITE_PLATFORM === 'K22')
    return 'https://app.k22.fr/v1/'

  else return 'https://manager.erp-cprinterfactory.com/v1/'
}
