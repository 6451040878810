<script setup lang="ts">
const { menu } = useAppContext()
</script>

<template>
  <TheMenu :menu="menu" />
</template>

<style scoped>

</style>
