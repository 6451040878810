<script setup lang="ts" generic="T">
import type { SearchContentConnection } from '@graphql'
import QueryResultItemOrderSupplier from '@/components/Query/ResultItemOrderSupplier.vue'
import QueryResultItemInvoiceSupplier from '@/components/Query/ResultItemInvoiceSupplier.vue'
import QueryResultItemQuotationSupplier from '@/components/Query/ResultItemQuotationSupplier.vue'
import QueryResultItemOrderCustomer from '@/components/Query/ResultItemOrderCustomer.vue'
import QueryResultItemInvoiceCustomer from '@/components/Query/ResultItemInvoiceCustomer.vue'
import QueryResultItemQuotationCustomer from '@/components/Query/ResultItemQuotationCustomer.vue'

const { startIndex, result } = defineProps<{
  result: SearchContentConnection
  startIndex: number
}>()
const { t } = useI18n()
const search = useSearch()

function getItemComponent() {
  switch (result.contentType) {
    case 'quotationSupplier':
      return QueryResultItemQuotationSupplier
    case 'quotationCustomer':
      return QueryResultItemQuotationCustomer
    case 'orderCustomer':
      return QueryResultItemOrderCustomer
    case 'orderSupplier':
      return QueryResultItemOrderSupplier
    case 'invoiceCustomer':
      return QueryResultItemInvoiceCustomer
    case 'invoiceSupplier':
      return QueryResultItemInvoiceSupplier
  }
}
const component = getItemComponent()

function getIcon() {
  switch (result.contentType) {
    case 'quotationSupplier':
    case 'quotationCustomer':
      return 'i-mdi-file-document-edit-outline'
    case 'orderCustomer':
    case 'orderSupplier':
      return 'i-mdi-file-document-outline'
    case 'invoiceCustomer':
    case 'invoiceSupplier':
      return 'i-mdi-receipt-text-outline'
    // case 'product':
    //   return 'i-mdi-package-variant-closed'
    // case 'contact':
    //   return 'i-mdi-account-group-outline'
    // case 'order':
    //   return 'i-mdi-file-document-outline'
    // case 'invoice':
    //   return 'i-mdi-receipt-text-outline'
    // case 'quotation':
    //   return 'i-mdi-file-document-edit-outline'
    // case 'lot':
    //   return 'i-mdi-warehouse'
    // case 'stock':
    //   return 'i-mdi-swap-horizontal'
    // case 'payment':
    //   return 'i-mdi-wallet-bifold-outline'
  }
}
const icon = getIcon()
</script>

<template>
  <div class="flex flex-col">
    <div class="flex items-center mb-1">
      <div :class="icon" class="font-size-6" />
      <div class="ml-3 font-size-4 font-bold">
        {{ t(`${result.contentType}.title`) }}
      </div>
    </div>
    <div v-for="(item, index) in result.items?.slice(0, 5)" :key="index" class="flex flex-col">
      <component
        :is="component"
        :value="item"
        class="py-1 px-6 font-size-0.812rem"
        :class="{
          'bg-gray-2': search.focus === startIndex + index,
        }"
        @mouseover="search.focus = startIndex + index"
      />
    </div>
    <div class="divider w-100% h-2px mt-2" />
  </div>
</template>

<style scoped>
.divider {
  background-color: var(--gray-300);
}
</style>

<i18n lang="json">
  {
    "en": {},
    "fr": {
      "quotationSupplier": {
        "title": "Devis Fournisseur"
      },
      "quotationCustomer": {
        "title": "Devis Client"
      },
      "orderSupplier": {
        "title": "Commandes Fournisseur"
      },
      "orderCustomer": {
        "title": "Commandes Client"
      },
      "invoiceSupplier": {
        "title": "Factures Fournisseur"
      },
      "invoiceCustomer": {
        "title": "Factures Client"
      },
      "quotation": {
        "title": "Devis"
      },
      "stock": {
        "title": "Movements de stock"
      }
    }
  }
  </i18n>
