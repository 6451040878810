<script setup lang="ts">
const isDark = useDark({
  onChanged(dark: boolean) {
    useHead({
      link: [
        { id: 'theme-link', rel: 'stylesheet', href: getTheme(dark) },
      ],
    })
  },
})

function getTheme(isDark: boolean) {
  if (isDark)
    return '/themes/dark.css'

  return '/themes/light.css'
}

function changeTheme() {
  isDark.value = !isDark.value
}
</script>

<template>
  <Button
    type="button"
    class="cursor-pointer"
    rounded
    text
    :icon="isDark ? 'i-mdi-weather-night' : 'i-mdi-weather-sunny'"
    :pt="{
      icon: {
        class: 'w-6 h-6 bg-gray-7',
      },
    }"
    @click="changeTheme()"
  />
</template>

<style scoped>
</style>
