<script setup lang="ts">
const currentEntity = useCurrentEntity()
const { currentApp, icon } = useAppContext()
const { t } = useI18n()

const apps = getApps()
const filterAppsFavorites = computed(() => {
  return apps
})

// OverlayPanel
const menu = ref()
function toggle(event: MouseEvent) {
  menu.value.toggle(event)
}

// close
function closeOverlay() {
  menu.value.hide()
}
</script>

<template>
  <div
    class="flex justify-start mx-5 border border-solid border-color b-rd-1 cursor-pointer"
    @click="toggle"
  >
    <div class="flex flex-items-center p-3 grow-1">
      <component :is="icon" v-if="icon" class="w-10 object-contain mr-4" />

      <div class="flex flex-col grow-1">
        <h6 class="font-size-1.125rem">
          {{ t(currentApp) }}
        </h6>
        <span class="font-size-0.812rem font-italic">{{ currentEntity.entity?.entity.name }}</span>
      </div>
      <div class="ml-2 text-2xl i-mdi-menu-down" />
    </div>

    <OverlayPanel
      id="overlay_menu"
      ref="menu"
      :base-z-index="1000"
      :pt="{ content: { class: '!p-0 !m-0' } }"
    >
      <Card
        :pt="{
          body: { class: '!p-0' },
          content: { class: '!p-0' },
        }"
      >
        <template #content>
          <div
            v-for="app in filterAppsFavorites"
            :key="app.title"
            class="flex flex-items-center cursor-pointer hover-bg-orange-1"
          >
            <RouterLink
              v-if="app.to"
              :to="app.to"
              class="decoration-none text-gray-7 text-center"
              @click="closeOverlay"
            >
              <div class="flex flex-items-center gap-x-5 py-3 px-5">
                <component :is="app.logo" class="w-1.875rem object-contain self-center grow-1 pt-2" />
                <h6 class="text-base font-500">
                  {{ t(app.title) }}
                </h6>
              </div>
            </RouterLink>
            <a
              v-else
              :href="app.href"
              target="_blank"
              class="decoration-none text-gray-7 text-center"
              @click="closeOverlay"
            >
              <div class="flex flex-items-center gap-x-5 py-3 px-5">
                <component :is="app.logo" class="w-1.875rem object-contain self-center grow-1 pt-2" />
                <h6 class="text-base font-500">
                  {{ t(app.title) }}
                </h6>
              </div>
            </a>
          </div>

          <Divider :pt="{ root: { class: '!p-0 !m-0' } }" />

          <div class="hover-bg-secondary-1 py-3 px-3 cursor-pointer">
            <RouterLink
              class="!hover-bg-secondary-1 decoration-none flex flex-items-center"
              :to="{ name: '/' }"
            >
              <i class="text-6 ml-2 bg-gray-6 i-mdi-domain" />
              <div class="ml-4 text-gray-6">
                {{ currentEntity.entity?.entity.name }}
              </div>
            </RouterLink>
          </div>
        </template>
      </Card>
    </OverlayPanel>
  </div>
</template>

<style scoped>
.border-color {
  border-color: var(--surface-border);
}
</style>
