export const useUserContext = defineStore('userContext', () => {
  const entityId = ref('')
  // NOTE le mail est stocke ici car sa valeur n'est pas sensee changer
  // pour le reste des infos users il faut passer par cognito
  // via useAuthenticator ou $auth
  const email = ref('')

  function $reset() {
    entityId.value = ''
    email.value = ''
  }

  return { entityId, email, $reset }
})
