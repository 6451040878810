export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "BlankLayout"
    }
  },
  {
    path: '/:entityId',
    /* internal name: '/[entityId]' */
    /* no component */
    children: [
      {
        path: '',
        name: '/[entityId]/',
        component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/index.vue'),
        /* no children */
        meta: {
          "layout": "IndexLayout"
        }
      },
      {
        path: 'account-settings',
        /* internal name: '/[entityId]/account-settings' */
        /* no component */
        children: [
          {
            path: ':tab',
            name: '/[entityId]/account-settings/[tab]',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/account-settings/[tab].vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'accounting',
        /* internal name: '/[entityId]/accounting' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/accounting/',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/accounting/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'administration',
        /* internal name: '/[entityId]/administration' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/administration/',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/index.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'balances',
            name: '/[entityId]/administration/balances',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/balances.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'entities',
            name: '/[entityId]/administration/entities',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/entities.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'pda',
            name: '/[entityId]/administration/pda',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/pda.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'pos',
            name: '/[entityId]/administration/pos',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/pos.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'printers',
            name: '/[entityId]/administration/printers',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/printers.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'users',
            name: '/[entityId]/administration/users',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/users.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'warehouses',
            name: '/[entityId]/administration/warehouses',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/administration/warehouses.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'billing',
        /* internal name: '/[entityId]/billing' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/billing/',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/billing/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'contacts',
        /* internal name: '/[entityId]/contacts' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/contacts/',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/contacts/index.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/contacts/index@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'suppliers',
            name: '/[entityId]/contacts/suppliers',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/contacts/suppliers.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/contacts/suppliers@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'logistic',
        /* internal name: '/[entityId]/logistic' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/logistic/',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/logistic/index.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/logistic/index@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'bat',
            name: '/[entityId]/logistic/bat',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/logistic/bat.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/logistic/bat@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'content',
            name: '/[entityId]/logistic/content',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/logistic/content.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'prices',
        /* internal name: '/[entityId]/prices' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/prices/',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/prices/index.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/prices/index@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'priceList',
            name: '/[entityId]/prices/priceList',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/prices/priceList.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/prices/priceList@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'products',
        /* internal name: '/[entityId]/products' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/products/',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/products/index.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/products/index@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'purchases',
        /* internal name: '/[entityId]/purchases' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/purchases/',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/purchases/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'reporting',
        /* internal name: '/[entityId]/reporting' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/reporting/',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/index.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/index@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byCostCenter',
            name: '/[entityId]/reporting/byCostCenter',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCostCenter.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCostCenter@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byCustomer',
            name: '/[entityId]/reporting/byCustomer',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCustomer.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCustomer@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byCustomerProducts',
            name: '/[entityId]/reporting/byCustomerProducts',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCustomerProducts.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCustomerProducts@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byCustomerProductsByDate',
            name: '/[entityId]/reporting/byCustomerProductsByDate',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCustomerProductsByDate.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byCustomerProductsByDate@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byFamilies',
            name: '/[entityId]/reporting/byFamilies',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byFamilies.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byFamilies@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byMonth',
            name: '/[entityId]/reporting/byMonth',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byMonth.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byMonth@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'byProduct',
            name: '/[entityId]/reporting/byProduct',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byProduct.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/reporting/byProduct@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'sales',
        /* internal name: '/[entityId]/sales' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/sales/',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/index.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/index@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'caByCustomers',
            name: '/[entityId]/sales/caByCustomers',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/caByCustomers.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'caByCustomersByProduct',
            name: '/[entityId]/sales/caByCustomersByProduct',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/caByCustomersByProduct.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'caByCustomersByProductByRangeDate',
            name: '/[entityId]/sales/caByCustomersByProductByRangeDate',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/caByCustomersByProductByRangeDate.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'caByFamilies',
            name: '/[entityId]/sales/caByFamilies',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/caByFamilies.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'caByProducts',
            name: '/[entityId]/sales/caByProducts',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/caByProducts.vue'),
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          },
          {
            path: 'content',
            /* internal name: '/[entityId]/sales/content' */
            /* no component */
            children: [
              {
                path: ':supplierId',
                /* internal name: '/[entityId]/sales/content/[supplierId]' */
                /* no component */
                children: [
                  {
                    path: ':contentId',
                    name: '/[entityId]/sales/content/[supplierId]/[contentId]',
                    components: {
                      'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/content/[supplierId]/[contentId].vue'),
                      'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/content/[supplierId]/[contentId]@filter.vue')
                    },
                    /* no children */
                    meta: {
                      "layout": "MenuLayout"
                    }
                  }
                ],
              }
            ],
          },
          {
            path: 'orders',
            name: '/[entityId]/sales/orders',
            components: {
              'default': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/orders.vue'),
              'filter': () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sales/orders@filter.vue')
            },
            /* no children */
            meta: {
              "layout": "MenuLayout"
            }
          }
        ],
      },
      {
        path: 'sandbox',
        name: '/[entityId]/sandbox',
        component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/sandbox.vue'),
        /* no children */
      },
      {
        path: 'search',
        name: '/[entityId]/search',
        component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/search.vue'),
        /* no children */
        meta: {
          "layout": "SearchLayout"
        }
      },
      {
        path: 'stocks',
        /* internal name: '/[entityId]/stocks' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[entityId]/stocks/',
            component: () => import('/codebuild/output/src3167080502/src/k22-app/src/pages/[entityId]/stocks/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]
