<script setup lang="ts">

</script>

<template>
  <a
    class="cursor-pointer"
    target="_blank"
  >
    <slot />
  </a>
</template>

<style scoped>

</style>
