import { createRouter, createWebHistory } from 'vue-router/auto'
import { checkUser } from './routerGuard/user'
import { prefetchData } from './routerGuard/prefetch'

const router = createRouter({
  history: createWebHistory(),
})

// // Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to) => {
  // check si user, entité
  const path = await checkUser(to)
  prefetchData()

  if (path)
    return path
})

export default router
