import type { Pinia, Store } from 'pinia'
import { getActivePinia } from 'pinia'

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export default function usePinia() {
  const pinia = getActivePinia() as ExtendedPinia

  if (!pinia)
    throw new Error('There is no active Pinia instance')

  const resetStore: Record<string, () => void> = {}
  const destroyStore: Record<string, () => void> = {}

  pinia._s.forEach((store, name) => {
    resetStore[name] = () => store.$reset()
    destroyStore[name] = () => destroy(store)
  })

  resetStore.all = () => pinia._s.forEach(store => store.$reset())
  destroyStore.all = () => pinia._s.forEach(store => destroy(store))

  function destroy(store: Store) {
    store.$dispose()
    delete pinia.state.value[store.$id]
  }

  // Reset stores individually
  // resetStore.app()
  // resetStore.user()
  // resetStore.auth()

  // Reset all stores
  // resetStore.all()

  return { resetStore, destroyStore }
}
