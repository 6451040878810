import type { I18nOptions } from 'vue-i18n'

export const numberFormats: I18nOptions['numberFormats'] = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard',
    },
    currency_0: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard',
      maximumFractionDigits: 0,
    },
    decimal_0: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal_0_1: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
      useGrouping: true,
    },
    decimal_2: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    decimal_2_2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    percent_2: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    kg: {
      style: 'unit',
      unit: 'kilogram',
      unitDisplay: 'short',
      useGrouping: true,
    },
  },
  'fr': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      currencyDisplay: 'symbol',
    },
    currency_0: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      currencyDisplay: 'symbol',
      maximumFractionDigits: 0,
    },
    decimal_0: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal_1: {
      style: 'decimal',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      useGrouping: true,
    },
    decimal_2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    decimal_3: {
      style: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
      useGrouping: true,
    },
    decimal_0_1: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
      useGrouping: true,
    },
    decimal_0_2: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    decimal_0_3: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
      useGrouping: true,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    percent_1: {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      useGrouping: false,
    },
    percent_2: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    kg: {
      style: 'unit',
      unit: 'kilogram',
      unitDisplay: 'short',
      useGrouping: true,
    },
    kg_1: {
      style: 'unit',
      unit: 'kilogram',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      unitDisplay: 'short',
      useGrouping: true,
    },
    kg_2: {
      style: 'unit',
      unit: 'kilogram',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      unitDisplay: 'short',
      useGrouping: true,
    },
    kg_3: {
      style: 'unit',
      unit: 'kilogram',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
      unitDisplay: 'short',
      useGrouping: true,
    },
  },
}
