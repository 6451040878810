export default
{
  contacts: [{
    title: 'apps.contacts.pages.customers.pageTitle',
    icon: 'i-mdi-circle-outline',
    to: { name: '/[entityId]/contacts/' },
    url: 'contacts',
  }, {
    title: 'apps.contacts.pages.suppliers.pageTitle',
    icon: 'i-mdi-circle-outline',
    to: { name: '/[entityId]/contacts/suppliers' },
    url: 'contacts/suppliers',
  }],
  logistic: [{
    title: 'apps.logistic.pages.ordersMonitoring.pageTitle',
    icon: 'i-mdi-file-document',
    to: { name: '/[entityId]/logistic/' },
    url: 'logistic',
  },
  // {
  //   title: 'apps.logistic.pages.bat.pageTitle',
  //   icon: 'i-mdi-file-document',
  //   to: { name: '/[entityId]/logistic/bat' },
  //   url: 'logistic/bat',
  // }
  ],
  prices: [{
    title: 'apps.prices.pages.prices.pageTitle',
    icon: 'i-mdi-sale-outline',
    to: { name: '/[entityId]/prices/priceList' },
    url: 'prices/priceList',
  }],
  sales: [{
    title: 'apps.sales.pages.orders.pageTitle',
    icon: 'i-mdi-circle-outline',
    to: { name: '/[entityId]/sales/orders' },
    url: 'sales/orders',
  }],
  reporting: [{
    title: 'apps.reporting.pages.reports.pageTitle',
    icon: 'i-mdi-chart-areaspline-variant',
    to: { name: '/[entityId]/reporting/' },
    url: 'reporting',
  }],
  administration: [{
    title: 'ListEntities',
    icon: 'i-mdi-circle-outline',
    to: { name: '/[entityId]/administration/entities' },
    url: 'administration/entities',
  }, {
    title: 'ListUsers',
    icon: 'i-mdi-circle-outline',
    to: { name: '/[entityId]/administration/users' },
    url: 'administration/users',
  }, {
    title: 'ListPDA',
    icon: 'i-mdi-circle-outline',
    to: { name: '/[entityId]/administration/pda' },
    url: 'administration/pda',
  }],
}
