<script setup lang="ts">
import logo from '@/assets/logo.svg?component'

const { icon = true } = defineProps<{
  icon?: boolean
}>()

const { t } = useI18n()
const { platform } = useEnv()
const search = useSearch()

const sb = ref()
const input = ref<HTMLInputElement | null>(null)

const dialog = ref()
onMounted(() => {
  search.input = input.value
  onClickOutside(dialog, () => {
    search.isOverlayOpen = false
  }, {
    ignore: [sb],
  })
})
</script>

<template>
  <div ref="sb" class="flex p-inputgroup !b-rd-10 bg-white dark:bg-bluegray-8 h-38px" v-bind="$attrs">
    <component :is="logo" v-if="platform === 'K22' && icon" class="w-6 h-100% object-contain color-primary ml-2 self-center" />
    <InputText
      ref="input"
      v-model="search.query"
      class=" bg-white dark:bg-bluegray-8"
      :placeholder="t('search')"
      :pt="{
        root: {
          class: '!b-rd-10 !border-none text-3 !font-italic pl-10',
        },
      }"
      @click="search.isOverlayOpen !== true ? (search.isOverlayOpen = true) : null"
      @keydown.up="search.changeFocus(-1)"
      @keydown.down="search.changeFocus(1)"
      @keydown.enter="search.triggerSearch()"
    />

    <Button
      v-if="search.query && !search.isFetching"
      icon="i-mdi-close"
      text
      :pt="{
        root: {
          class: `bg-white dark:bg-transparent !border-none !b-rd-r-10 !w-12 !h-38px`,
        },
        icon: { class: 'bg-primary dark:bg-bluegray-2 !w-1.2rem !h-1.2rem' },
      }"
      @click="search.clear()"
    />
    <ProgressSpinner
      v-else-if="search.isFetching"
      stroke-width="4"
      :pt="{
        root: {
          class: `bg-white dark:bg-transparent !border-none !b-rd-r-10 !w-12 !h-38px`,
        },
        spinner: { class: '!w-1.2rem !h-1.2rem' },
      }"
    />
    <Avatar
      v-else
      icon="i-mdi-search"
      :pt="{
        root: {
          class: `self-center bg-white dark:bg-transparent !border-none !b-rd-r-10 !w-12 !h-38px`,
        },
        icon: { class: 'bg-primary dark:bg-bluegray-2 !w-1.2rem !h-1.2rem' },
      }"
    />
  </div>
  <Dialog

    v-model:visible="search.isOverlayOpen"
    position="top"
    :style="{ width: '800px' }"
    :closable="false"
    :draggable="false"
    :pt="{
      root: {
        class: `!absolute !top-56px !transform-none`,
      },
      header: {
        class: `!p-1`,
      },
      content: {
        class: `!p-2 !pt-0`,
      },
    }"
  >
    <QueryOverlay ref="dialog" />
  </Dialog>
</template>

<style lang="css" scoped>

</style>

<i18n lang="json">
{
  "en": {},
  "fr": {
    "search": "Rechercher un nom, une facture, un montant ..."
  }
}
</i18n>
