<script setup lang="ts">
const route = useRoute()
const layout = computed(() => {
  return route.meta.layout || 'BlankLayout'
})
</script>

<template>
  <component :is="layout">
    <template #filter>
      <router-view name="filter" />
    </template>
    <router-view />
  </component>
</template>

<style>
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* Prevent adjustments of font size after orientation changes in iOS */
  word-break: normal;
  -moz-tab-size: 4;
  tab-size: 4;
}

body{
  color: var(--text-color);
  background-color: var(--surface-ground);
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
