import billing from '@/assets/apps/Billing.svg?component'
import logistic from '@/assets/apps/Logistic.svg?component'
import products from '@/assets/apps/Products.svg?component'
import purchases from '@/assets/apps/Purchases.svg?component'
import sales from '@/assets/apps/Sales.svg?component'
import stocks from '@/assets/apps/Stocks.svg?component'

import type { App } from '@/types/App'

export function getApps() {
  const currentUser = useCurrentUser()
  // const currentEntity = useCurrentEntity()
  const platform = import.meta.env.VITE_PLATFORM || 'K22'

  // CPF
  const productCPF = `https://manager.erp-cprinterfactory.com/v1/${currentUser.entityId}/product?charges=0`
  const salesCPF = `https://manager.erp-cprinterfactory.com/v1/${currentUser.entityId}/sales`
  const purchasesCPF = `https://manager.erp-cprinterfactory.com/v1/${currentUser.entityId}/purchases`
  const inventoryCPF = `https://manager.erp-cprinterfactory.com/v1/${currentUser.entityId}/inventory`
  const billingCPF = `https://manager.erp-cprinterfactory.com/v1/${currentUser.entityId}/accounting/journal`

  // K22
  const productMain = `https://app.k22.fr/v1/${currentUser.entityId}/product?charges=0`
  // const productBeta = `https://beta.k22.fr/v1/${currentUser.entityId}/product?charges=0`

  const salesMain = `https://app.k22.fr/v1/${currentUser.entityId}/sales`
  // const salesBeta = `https://beta.k22.fr/v1/${currentUser.entityId}/sales`

  const purchasesMain = `https://app.k22.fr/v1/${currentUser.entityId}/purchases`
  // const purchasesBeta = `https://beta.k22.fr/v1/${currentUser.entityId}/purchases`

  const inventoryMain = `https://app.k22.fr/v1/${currentUser.entityId}/inventory`
  // const inventoryBeta = `https://beta.k22.fr/v1/${currentUser.entityId}/inventory`

  const billingMain = `https://app.k22.fr/v1/${currentUser.entityId}/accounting/journal`
  // const billingBeta = `https://beta.k22.fr/v1/${currentUser.entityId}/accounting/journal`

  const apps: App[] = [
    {
      logo: products,
      title: 'apps.products.title',
      subtitle: 'apps.products.subtitle',
      // to: { name: '/[entityId]/products/' },
      color: '#dd6bb6',
      href: platform === 'CPF' ? productCPF : productMain,
      disabled: false,
    },
    {
      logo: sales,
      title: 'apps.sales.title',
      subtitle: 'apps.sales.subtitle',
      // to: { name: '/[entityId]/sales/' },
      color: '#ff7d08',
      href: platform === 'CPF' ? salesCPF : salesMain,
      disabled: false,
    },
    {
      logo: purchases,
      title: 'apps.purchases.title',
      subtitle: 'apps.purchases.subtitle',
      // to: { name: '/[entityId]/purchases/' },
      color: '#ffcd38',
      href: platform === 'CPF' ? purchasesCPF : purchasesMain,
      disabled: false,
    },
    {
      logo: logistic,
      title: 'apps.logistic.title',
      subtitle: 'apps.logistic.subtitle',
      to: { name: '/[entityId]/logistic/' },
      color: '#ff7d08',
      disabled: false,
    },
    {
      logo: stocks,
      title: 'apps.stocks.title',
      subtitle: 'apps.stocks.subtitle',
      // to: { name: '/[entityId]/stocks/' },
      href: platform === 'CPF' ? inventoryCPF : inventoryMain,
      color: '#91bf95',
      disabled: false,
    },
    {
      logo: billing,
      title: 'apps.billing.title',
      subtitle: 'apps.billing.subtitle',
      // to: { name: '/[entityId]/billing/' },
      color: '#a87cff',
      href: platform === 'CPF' ? billingCPF : billingMain,
      disabled: false,
    },
  ]

  return apps
}
