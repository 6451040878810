<script setup lang="ts">
import { I18n } from 'aws-amplify/utils'

import {
  Authenticator,
  translations,
  useAuthenticator,
} from '@aws-amplify/ui-vue'
import { fetchUserAttributes } from 'aws-amplify/auth'
import LogoK22 from '@/assets/logo_with_name_K22.svg?component'
import '@/styles/amplify.css'

const userContext = useUserContext()
const route = useRoute('/[entityId]/')
const auth = useAuthenticator()

watch(
  () => auth.authStatus,
  async (newValue) => {
    if (newValue === 'unauthenticated') {
      userContext.$reset()
      console.log('unauthenticated')
    }
    if (newValue === 'authenticated') {
      userContext.entityId = route.params.entityId
      const userInfo = await fetchUserAttributes()
      if (!userInfo.email)
        throw new Error('No email in attributes')

      userContext.email = userInfo.email
    }
  },
)

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: 'local',
})

I18n.putVocabularies(translations)
const dict = {
  fr: {
    'Reset Password': t('authenticator.resetPassword'),
    'Incorrect username or password': t('authenticator.incorrectUsername'),
    'Incorrect username or password.': t('authenticator.incorrectUsername'),
    'Attempt limit exceeded, please try after some time.': t(
      'authenticator.limitExceeded',
    ),
    'Enter your Password': t('authenticator.enterPassword'),
    'Please confirm your Password': t('authenticator.confirmPassword'),
    'Name': t('authenticator.name'),
    'Enter your name': t('authenticator.enterName'),
    'Enter your email': t('authenticator.enterEmail'),
  },
}
I18n.putVocabularies(dict)
I18n.setLanguage(locale.value)

const formFields = {
  signIn: {
    username: {
      placeholder: t('authenticator.form.signIn.username.placeholder'),
    },
    password: {
      placeholder: t('authenticator.form.signIn.password.placeholder'),
    },
  },
}
</script>

<template>
  <div data-amplify-theme class="flex justify-center min-h-100vh">
    <Authenticator :login-mechanisms="['email']" :hide-sign-up="true" :form-fields="formFields">
      <template #header>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <LogoK22 class="color-primary h-15" />
        </div>
      </template>
      <!-- <template #header /> -->
      <template #sign-in-header>
        <div class="amplify-heading" style="padding: var(--amplify-components-authenticator-form-padding)">
          <h2 class="text-4xl fw-700">
            {{ t("authenticator.signInHeader.title") }}
          </h2>
          <br>
          <h3 class="mt-2 text-3xl fw-700">
            {{ t("authenticator.signInHeader.subtitle") }}
          </h3>
        </div>
      </template>
      <template #footer />
      <slot />
    </Authenticator>
  </div>
</template>

<style>
[data-amplify-theme] {
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
  --amplify-radii-xs: 1rem;
  --amplify-radii-small: 2rem;
  --amplify-radii-medium: 2rem;
  --amplify-radii-large: 2rem;
  --amplify-radii-xl: 3rem;
  --amplify-space-xs: 0.75rem;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-space-xl: 3rem;
  --amplify-components-authenticator-container-width-max: 650px;
  --amplify-components-button-active-background-color: theme("colors.primary.100"
    );
  --amplify-components-button-active-border-color: theme("colors.primary.500");
  --amplify-components-button-border-radius: 0.3rem;
  --amplify-components-button-focus-border-color: theme("colors.primary.400");
  --amplify-components-button-focus-box-shadow: none;
  --amplify-components-button-hover-background-color: theme("colors.gray.200");
  --amplify-components-button-hover-background-color: var(--v-theme-grey-50);
  --amplify-components-button-hover-border-color: theme("colors.gray.500");
  --amplify-components-button-hover-border-color: theme("colors.primary.400");
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-active-border-color: transparent;
  --amplify-components-button-link-active-color: theme("colors.primary.400");
  --amplify-components-button-link-color: theme("colors.gray.500");
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-focus-border-color: transparent;
  --amplify-components-button-link-focus-box-shadow: none;
  --amplify-components-button-link-focus-color: theme("colors.primary.400");
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-hover-border-color: transparent;
  --amplify-components-button-link-hover-color: theme("colors.primary.400");
  --amplify-components-button-primary-focus-box-shadow: none;
  --amplify-components-divider-label-background-color: theme("colors.gray.200");
  --amplify-components-field-label-color: var(--v-theme-grey-800);
  --amplify-components-fieldcontrol-border-color: theme("colors.gray.500");
  --amplify-components-fieldcontrol-border-radius: 0.25rem;
  --amplify-components-fieldcontrol-border-width: 1px;
  --amplify-components-fieldcontrol-color: var(--v-theme-grey-800);
  --amplify-components-fieldcontrol-focus-border-color: theme("colors.primary.400"
    );
  --amplify-components-fieldcontrol-focus-box-shadow: none;
  --amplify-components-heading-color: var(--v-theme-grey-800);
  --amplify-components-radio-button-border-width: var(--amplify-border-widths-small);
  --amplify-components-radio-button-padding: var(--amplify-border-widths-small);
  --amplify-components-textfield-color: var(--v-theme-grey-50);
}

/* CARD AUTHENTICATOR */
[data-amplify-authenticator] [data-amplify-router] {
  border-color: transparent;
  box-shadow: none;
  background-color: rgb(var(--v-theme-surface)) !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  @apply rounded-2;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding-block: 20px;
  padding-inline: var(--amplify-components-authenticator-form-padding);
}

/* icone svg pour voir le mot de passe */
.amplify-icon {
  fill: theme("colors.gray.500");
}

.amplify-heading {
  @apply fw-700;
}

.amplify-divider {
  @apply b-1 mt-6;
}

.amplify-authenticator__sign-in-base {
  @apply p-y-10;
}

.amplify-button--primary {
  animation: rainbow 8s ease alternate 0s infinite;
  background-color: var(--b-c-brand);
  color: white;
  font-size: large;
  transform: translateZ(360deg);
  transition: background-color 0.25s;
}

.amplify-button--primary:hover {
  background-color: var(--b-c-brand-light);
  color: white;
}

.v-theme--dark .amplify-input::placeholder {
  color: theme("colors.gray.500");
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-end-start-radius: var(--amplify-components-fieldcontrol-border-radius);
  border-start-start-radius: var(--amplify-components-fieldcontrol-border-radius);
}

/* ALERT */
.amplify-alert--error {
  border-radius: var(--amplify-components-fieldcontrol-border-radius);
}

/* REGLES */
[data-amplify-authenticator] [data-amplify-container] {
  @apply w-100%;
}
</style>

<i18n lang="json">
{
  "en": {
    "authenticator": {
      "signInHeader": {
        "title": "",
        "subtitle": ""
      },
      "form": {
        "signIn": {
          "username": {
            "placeholder": ""
          },
          "password": {
            "placeholder": ""
          }
        }
      },
      "resetPassword": "",
      "incorrectUsername": ""
    },
    "about": "",
    "accompanying": "",
    "activateAccount": "",
    "company": "",
    "equipment": "",
    "features": "",
    "gtc": "",
    "hire": "",
    "home": "",
    "legal": "",
    "legalNotices": "",
    "login": "",
    "management": "",
    "networkEquipment": "",
    "price": "",
    "privacy": "",
    "professionalEquipment": "",
    "securityEquipment": "",
    "solutions": "",
    "team": ""
  },
  "fr": {
    "authenticator": {
      "signInHeader": {
        "title": "Bonjour !",
        "subtitle": "Heureux de vous revoir ! 👋"
      },
      "form": {
        "signIn": {
          "username": {
            "placeholder": "Entrer votre mail"
          },
          "password": {
            "placeholder": "Mot de passe"
          }
        }
      },
      "enterPassword": "Entrez votre mot de passe",
      "confirmPassword": "Confirmer votre mot de passe",
      "name": "Nom",
      "enterName": "Entrer votre nom",
      "enterEmail": "Entrer votre email",
      "resetPassword": "Réinitialisez votre mot de passe",
      "incorrectUsername": "Identifiant ou mot de passe incorrect",
      "limitExceeded": "Nombre d'essais dépassé, veuillez réessayer ultérieurement."
    },
    "about": "A propos",
    "accompanying": "Accompagnement",
    "activateAccount": "Activer mon compte",
    "company": "L'entreprise",
    "equipment": "Équipement",
    "features": "Fonctionnalités",
    "gtc": "CGU",
    "hire": "On recrute",
    "home": "Accueil",
    "legal": "Légal",
    "legalNotices": "Mentions légales",
    "login": "Connexion",
    "management": "Gestion et pilotage",
    "networkEquipment": "Équipement réseau",
    "price": "Tarifs",
    "privacy": "Confidentialité",
    "professionalEquipment": "Équipement professionnel",
    "securityEquipment": "Équipement sécurité",
    "solutions": "Solution",
    "team": "L'équipe"
  }
}
</i18n>
