<script setup lang="ts">
const { item } = defineProps<{
  item: any
}>()
const { t } = useI18n()

const isDark = useDark()
const currentUser = useCurrentUser()
const route = useRoute()
const isHighlighted = ref(false)

const r1 = ref(route.fullPath.split('/')[2])
const r2 = ref(`/${currentUser.entityId}/${item.url}`.split('/')[2])

// console.log('TheMenuItem route:', r1.value, 'url:', r2.value)

const getIconColor = computed(() => {
  // if (isHighlighted.value && route.fullPath === r2.value)
  //   return ''

  if (r1.value === r2.value)
    return 'white'

  if (isDark.value) {
    if (isHighlighted.value && r1.value !== r2.value)
      return 'white'
    else return 'gray'
  }
  else {
    if (isHighlighted.value && r1.value !== r2.value)
      return 'black'
    else return 'gray'
  }
})

const getTextColor = computed(() => {
  // if (isHighlighted.value && r1.value === r2.value)
  //   return ''

  if (r1.value === r2.value)
    return '!text-white'

  if (isDark.value)
    return '!text-gray'
  else
    return '!text-gray-7'
})

const getCss = computed(() => {
  if (isHighlighted.value && r1.value === r2.value)
    return 'bg-primary-4 elevation-2  rounded-r-10'

  if (r1.value === r2.value)
    return 'bg-primary-5 elevation-2  rounded-r-10'

  if (isHighlighted.value) {
    if (isDark.value)
      return 'bg-secondary-8 bg-opacity-20 rounded-r-10 transition'

    else
      return 'bg-secondary-4 bg-opacity-20 rounded-r-10 transition'
  }
})
</script>

<template>
  <div
    v-ripple
    class="flex items-center p-ripple pl-4 py-2.5 mb-1 cursor-pointer"
    :class="getCss"
    @mouseover="isHighlighted = true"
    @mouseout="isHighlighted = false"
  >
    <RouterLink
      :to="item.to"
      class="flex flex-items-center grow-1"
      :class="getTextColor"
    >
      <i
        :style="{ color: getIconColor }"
        :class="item.icon"
      />

      <div class="ml-3">
        {{ t(item.title) }}
      </div>
    </RouterLink>
  </div>
</template>

<style scoped>
.transition {
  transition: background-color 0.4s ease;
}
</style>
