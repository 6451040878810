import { useGetUserQuery, useUpdateUserMutation } from '@graphql'
import { useUserContext } from '@/store/userContext'

export const useCurrentUser = defineStore('useCurrentUser', () => {
  const { entityId, email } = storeToRefs(useUserContext())

  const { queryClient } = useQueryClientSingleton()
  const variables = reactive({ entityId, email })

  const query = useGetUserQuery(
    variables,
    { staleTime: 5 * 60 * 1000 },
  )

  const mutation = useUpdateUserMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['GetUser', variables],
      })
    },
  })

  const defaultWarehouseId = computed(() => {
    return query.data.value?.user?.defaultWarehouseId || 'none'
  })

  const warehouseIdStored = useStorage('warehouses', { none: 'none' } as Record<string, string>)

  function setWarehouseId(value: string) {
    const key = entityId.value || 'none'
    warehouseIdStored.value[key] = value
  }

  const warehouseId = computed(() => {
    const key = entityId.value || 'none'
    return warehouseIdStored.value[key] || defaultWarehouseId.value
  })

  return {
    entityId,
    email,
    getCurrentUser: query,
    mutateCurrentUser: mutation,
    user: computed(() => query.data.value?.user || null),
    warehouseId,
    setWarehouseId,
  }
})
