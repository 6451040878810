interface TelemetryData {
  id: number
  query: string
  start: number
  end?: number
  duration?: number
}

export const useTelemetry = defineStore('useTelemetry', () => {
  const sequence = ref(0)
  const lastQueries: Ref<TelemetryData[]> = ref([])

  function add(data: TelemetryData) {
    lastQueries.value.unshift(data)
    lastQueries.value = lastQueries.value.slice(0, 5)
  }

  function start(query: string): number {
    add({
      id: sequence.value,
      query: query.split('(')[0].slice(11),
      start: Date.now(),
    })
    return sequence.value++
  }

  function stop(id: number) {
    const data = lastQueries.value.find(data => data.id === id)
    if (!data)
      return
    data.end = Date.now()
    data.duration = data.end - data.start
  }

  return {
    lastQueries,
    start,
    stop,
  }
})
