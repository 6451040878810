<script setup lang="ts">
import type { SearchContent } from '@graphql'

const { value: order } = defineProps<{
  value: SearchContent
}>()
const { n, locale, t } = useI18n()
const currentUser = useCurrentUser()
</script>

<template>
  <div class="">
    <LinkExternal class="flex" :href="`${getBaseUrl()}${currentUser.entityId}/content/${order.contentId}?content=FF`">
      <div class="basis-20% text-truncate ">
        {{ order.contentRef }}
      </div>
      <div class="basis-20% text-truncate text-end pr-8 ">
        <span v-if="order.total_ht">{{ n(order.total_ht, 'currency') }}</span>
      </div>
      <div class="basis-30% text-truncate">
        <span v-if="order.address">{{ order.address.name }}</span>
      </div>
      <div class="ml-auto flex">
        <div class="mr-4">
          <Tag class="!py-0.1rem">
            {{ t(order.state) }}
          </Tag>
        </div>
        <div class="min-w-80px text-right">
          <span v-if="order.datedl">{{ DateTime.fromISO(order.datedl).setLocale(locale).toLocaleString({ month: 'long', day: 'numeric' }) }}</span>
        </div>
      </div>
    </LinkExternal>
  </div>
</template>

<i18n lang="json">
  {
    "en": {},
    "fr": {
      "ARCHIVED": "Archivée",
      "CLOSED": "Clotûrée",
      "DELETED": "Supprimée",
      "DISPUTED_DRAW": "Litige",
      "DRAFT": "Brouillon",
      "ERROR": "Erreur",
      "NOT_PAID": "Impayée",
      "OPEN": "Ouverte",
      "PAID": "Payée",
      "PAID_BACK": "Soldée",
      "RELAUNCH1": "Relance 1",
      "RELAUNCH2": "Relance 2",
      "STARTED": "Rglt commencé",
      "VALIDATED": "Validée"
    }
  }
  </i18n>
