import type { SearchContentConnection } from '@graphql'
import { useSearchContentsQuery } from '@graphql'

export const useSearch = defineStore('useSearch', () => {
  const { warehouseId, entityId } = storeToRefs(useCurrentUser())

  const isOverlayOpen = ref(false)
  const isDebouncing = ref(true)
  const query: Ref<string> = ref('')
  // const quickSearch: Ref<string> = ref('')
  // const results: Ref<SearchContentConnection | []> = ref([])
  const focus = ref(-1)
  const input = ref()
  // const regexValidQuery = /^((facture:|contact:)(\S+) )*((facture:|contact:)?(\S+) *)$/
  const regexSubQueries = /((?:devis|commande|facture|client|fournisseur|montant|ref)(?::\S+)?) /gi

  // const enabled = computed(() => {
  //   return quickSearch.value.length > 1
  // })
  const enabled = ref(false)

  const variables = reactive({
    entityId,
    // quickSearch,
    quickSearch: query,
    limit: 5,
    warehouseId,
  })

  const { isLoading, isFetching, data, error } = useSearchContentsQuery(variables, {
    enabled,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    select: data => data.searchContents,
  })

  const results: ComputedRef<SearchContentConnection[]> = computed(() => {
    return data.value || []
  })

  const itemsCount = computed(() => {
    // const additionalRow = results.value.length ? 1 : 0
    const additionalRow = 0
    return results.value.reduce((count, result) => count + Math.min(result?.items?.length || 0, 5), 0)
      + additionalRow
  })

  const categories = computed(() => {
    return results.value.map((result) => {
      return { name: result.contentType, count: (result?.items?.length || 0) }
    })
  })

  // function setQuickSearch() {
  //   quickSearch.value = query.value
  //   isDebouncing.value = false
  // }

  // const debouncedSetQuickSearch = debounce(setQuickSearch, 750)

  // function handleDebounce() {
  //   isDebouncing.value = true
  //   debouncedSetQuickSearch()
  // }

  watch(query, (newValue, oldValue) => {
    // if (newValue.length > 1)
    //   handleDebounce()
    if (newValue === null || newValue === '') {
      isOverlayOpen.value = false
      focus.value = -1
      return
    }
    if (oldValue === null || oldValue === '')
      isOverlayOpen.value = true
  })

  function clear() {
    query.value = ''
    resetInputFocus()
  }

  function setQueryWithFocus(value: string) {
    query.value = value
    resetInputFocus()
  }

  function resetInputFocus() {
    if (input.value)
      input.value.$el.focus()
  }

  function changeFocus(value: number) {
    if (value === -1 && focus.value === 0) {
      focus.value = itemsCount.value - 1
      return
    }
    focus.value = (focus.value + value) % itemsCount.value
  }

  function getStartIndex(index: number) {
    return results.value.slice(0, index).reduce((count, result) => count + Math.min(result?.items?.length || 0, 5), 0)
  }

  const currentSubQueries = computed(() => {
    if (!query.value)
      return []
    return [...query.value.matchAll(regexSubQueries)].map(match => match[1])
  })

  const lastSubQuery = computed(() => {
    if (!query.value)
      return ''
    return query.value
      .split(`${currentSubQueries.value[currentSubQueries.value.length - 1]} `)
      .slice(-1)[0]
  })

  const activeFilters = computed(() => {
    const filters = currentSubQueries.value.map(query => query.split(':')[0])
    filters.push(lastSubQuery.value.split(':')[0])
    return filters
  })

  function addFilters(...filters: string[]) {
    // let joinedQuery = currentSubQueries.value.join(separator)
    // if (joinedQuery.length > 0)
    //   joinedQuery += ' '
    // query.value = `${joinedQuery}${filter}:${lastSubQuery.value}`

    const separator = query.value.endsWith(' ') ? '' : ' '
    for (const filter of filters) {
      if (!activeFilters.value.includes(filter))
        query.value = `${query.value}${separator}${filter}`
    }
    resetInputFocus()
  }

  function getFilters(type: string): string[] {
    switch (type) {
      case 'quotationCustomer':
        return ['devis', 'client']
      case 'quotationSupplier':
        return ['devis', 'fournisseur']
      case 'orderCustomer':
        return ['commande', 'client']
      case 'orderSupplier':
        return ['commande', 'fournisseur']
      case 'invoiceCustomer':
        return ['facture', 'client']
      case 'invoiceSupplier':
        return ['facture', 'fournisseur']
    }
    return []
  }

  function triggerSearch() {
    enabled.value = true
    enabled.value = false
  }

  return {
    isOverlayOpen,
    query,
    setQueryWithFocus,
    clear,
    results,
    focus,
    changeFocus,
    getStartIndex,
    categories,
    addFilters,
    currentSubQueries,
    activeFilters,
    lastSubQuery,
    input,
    isLoading,
    isFetching,
    isDebouncing,
    data,
    error,
    getFilters,
    triggerSearch,
  }
})
