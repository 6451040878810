<script setup lang="ts">
import { signOut } from 'aws-amplify/auth'
import type { DrawerMenuItem } from '@/types/DrawerMenuItem'

const { t } = useI18n()
const currentEntity = useCurrentEntity()
const currentUser = useCurrentUser()
const router = useRouter()

// User
const initials = computed(() => {
  if (!currentUser.user?.lastName && !currentUser.user?.firstName)
    return 'AZ'
  const firstLetter = currentUser.user?.firstName?.charAt(0) || ''
  const secondLetter = currentUser.user?.lastName?.charAt(0) || ''
  return `${firstLetter}${secondLetter}`
})

// Logout
async function logout() {
  await signOut()
  usePinia().destroyStore.all()
  router.push('/')
}

// Menu
// OverlayPanel
const menu = ref()
// close
function closeOverlay() {
  menu.value.hide()
}
// open
function toggle(event: MouseEvent) {
  menu.value.toggle(event)
}

const userProfileList: DrawerMenuItem[] = [
  { type: 'navItem', icon: 'i-mdi-cog-outline', title: 'ParametersUser', to: { name: '/[entityId]/account-settings/[tab]', params: { tab: 'account', entityId: currentUser.entityId } } },
  // { type: 'divider', title: '', icon: '' },
  // { type: 'navItem', icon: 'i-mdi-cogs', title: 'Parameters', to: { name: '/[entityId]/administration/' } },
  // { type: 'navItem', icon: 'i-mdi-lifebuoy', title: 'Support', href: 'https://themeselection.com/support/' },
  // { type: 'navItem', icon: 'i-mdi-file-document-outline', title: 'FAQ', href: 'https://docs.k22.fr/' },
  { type: 'divider', title: '', icon: '' },
]
</script>

<template>
  <div class="flex flex-items-center">
    <Avatar
      class="me-3 cursor-pointer"
      shape="circle"
      :label="initials"
      :pt="{
        root: { class: '!bg-primary-3 dark:!bg-primary-900 color-white' },
      }"
      @click="toggle"
    />
  </div>

  <OverlayPanel
    id="overlay_menu"
    ref="menu"
    :popup="true"
    :pt="{ content: { class: '!p-0 !m-0' } }"
  >
    <Card
      class="flex flex-col w-310px"
      :pt="{
        body: { class: '!p-0 !m-0' },
        title: { class: '!p-0 !m-0' },
        content: { class: '!p-0 !m-0' },
      }"
    >
      <!-- USER -->
      <template #title>
        <div
          class="my-4 flex flex-items-center grow-1"
        >
          <Avatar
            class="ml-3 me-3"
            shape="circle"
            :label="initials"
            :pt="{
              root: { class: '!bg-primary-3 dark:!bg-primary-900 color-white' },
            }"
          />
          <div class="ml-2 flex flex-col">
            <div class="text-5">
              {{ currentUser.user?.fullName }}
            </div>
          </div>
        </div>

        <Divider
          :pt="{ root: { class: '!p-0 !m-0' } }"
        />
      </template>

      <!-- MENU -->
      <template #content>
        <div
          v-for="(item, index) in userProfileList"
          :key="index"
          class="flex items-center "
          :class="item.type !== 'divider' ? '!hover-bg-secondary-1 !dark:hover-bg-secondary-800 !dark:hover-bg-opacity-20 cursor-pointer' : null "
        >
          <RouterLink
            v-if="item.to"
            class="my-3 decoration-none flex flex-items-center grow-1"
            :to="item.to"
            @click="closeOverlay"
          >
            <i class="text-6 ml-2 bg-gray-6" :class="item.icon" />
            <div class="ml-4 text-gray-6">
              {{ t(item.title) }}
            </div>
          </RouterLink>

          <a
            v-else-if="item.href"
            class="my-3 decoration-none  flex flex-items-center grow-1"

            target="_blank"
            :href="item.href"
          >
            <i class="text-6 ml-2 bg-gray-6" :class="item.icon" />
            <div class="ml-4 text-gray-6">
              {{ t(item.title) }}
            </div>
          </a>

          <Divider
            v-else
            :pt="{ root: { class: '!p-0 !m-0' } }"
          />
        </div>

        <Divider
          :pt="{ root: { class: '!p-0 !m-0' } }"
        />

        <!-- Chgt entité -->
        <div
          class="hover-bg-secondary-1 py-3 cursor-pointer"
        >
          <RouterLink
            class="!hover-bg-secondary-1 decoration-none flex flex-items-center"
            :to="{ name: '/' }"
          >
            <i class="text-6 ml-2 bg-gray-6 i-mdi-domain" />
            <div class="ml-4 text-gray-6">
              {{ currentEntity.entity?.entity.name }}
            </div>
          </RouterLink>
        </div>

        <!-- Logout -->
        <Divider
          :pt="{ root: { class: '!p-0 !m-0' } }"
        />
        <div
          class="py-3 !hover-bg-secondary-1 cursor-pointer flex flex-items-center"
          @click=" logout() "
        >
          <i class="text-6 ml-2 bg-gray-6 i-mdi-logout" />
          <div class="ml-4 text-gray-6">
            {{ t('Logout') }}
          </div>
        </div>
      </template>
    </Card>
  </OverlayPanel>
</template>
