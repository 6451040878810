<script setup lang="ts">
import Search from '@/assets/search.svg?component'

const { t } = useI18n()

const examples = [
  { label: t('example.facture'), query: 'facture ' },
  { label: t('example.factureMontant'), query: 'facture montant:' },
  { label: t('example.ref'), query: 'ref:' },
]
</script>

<template>
  <div class="flex flex-col justify-center py-2 items-center">
    <Search class="w-150px" />
    <div class="font-size-5 font-700 lh-6 mb-4">
      {{ t('title') }}
    </div>
    <div class="font-size-4 max-w-60% text-center lh-6 text-secondary mb-6">
      {{ t('description') }}
    </div>
    <div class="flex flex-col gap-y-2">
      <QueryOverlayFilterExample
        v-for="(example, index) in examples"
        :key="index"
        :label="example.label"
        :query="example.query"
      />
    </div>
  </div>
</template>

<i18n lang="json">
  {
    "en": {},
    "fr": {
      "title": "Aucun résultat trouvé",
      "description": "Votre recherche ne trouve aucune correspondance dans la base de données. Voici quelques idées de filtres :",
      "example": {
        "facture": "vos factures",
        "factureMontant": "vos factures d'un montant spécifique",
        "ref": "une référence particulière"
      }
    }
  }
  </i18n>
