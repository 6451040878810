<script setup lang="ts">
const search = useSearch()

const firstQuery = ref(true)

watch(() => search.isFetching, (newValue) => {
  if (newValue)
    firstQuery.value = false
})
</script>

<template>
  <div class="py-2 px-4">
    <div v-if="!search.isFetching">
      <QueryOverlayHelp v-if="firstQuery" />
      <QueryOverlayEmpty v-else-if="search.results.length === 0 " />
      <div v-else>
        <QueryFilters class="mb-2" />
        <!-- <div :class="{ 'bg-primary': search.focus === 0 }" @mouseover="search.focus = 0">
          all results {{ search.focus }}
        </div> -->
        <QueryResultGroupContent
          v-for="(group, index) in search.results"
          :key="group.contentType"
          class="mb-2"
          :result="group"
          :start-index="search.getStartIndex(index)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
