import type { GraphQLResult } from '@aws-amplify/api-graphql'
import { generateClient } from 'aws-amplify/api'
//import * as Sentry from '@sentry/vue'
import { fetchAuthSession } from 'aws-amplify/auth'

async function getToken() {
  const { tokens } = await fetchAuthSession()
  return tokens?.idToken?.toString() || ''
}

export function useGraphQLAPI<TData, TVariables>(query: string, variables?: TVariables, _options?: RequestInit['headers']): (() => Promise<TData>) {
  return async () => {
    const telemetry = useTelemetry()

    // console.log(authToken.value)
    // we only use for query
    const idTelemetry = telemetry.start(query)
    const client = generateClient()
    interface MyOperationType { variables?: TVariables, result: TData }
    try {
      const res = await client.graphql<MyOperationType>({
        query,
        variables,
        authToken: await getToken(),
      }) as GraphQLResult<TData>
      return res.data
    }
    catch (error: unknown) {
      if (typeof error === 'object' && error !== null
        && 'data' in error && 'errors' in error) {
        /*Sentry.captureException(error, {
          extra: {
            errors: JSON.stringify(error.errors),
            data: JSON.stringify(error.data),
            trace: Error.captureStackTrace({}),
          },
          tags: {
            source: 'graphql',
          },
        })*/
        console.error(error)
        return error.data as TData
      }
      console.error(error)
      throw error
    }
    finally {
      telemetry.stop(idTelemetry)
    }
  }
}
