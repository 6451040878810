import type { StartEndDateInput } from '@graphql'
import { useGetProductsAllQuery, useGetStocksQuery, useGetSuppliersAllQuery, useGetUserQuery } from '@graphql'
import { sortBy } from 'lodash-es'

export const useCurrentEntity = defineStore('useCurrentEntity', () => {
  const currentUser = useCurrentUser()
  const { warehouseId } = storeToRefs(currentUser)
  const { queryClient } = useQueryClientSingleton()

  const { entityId } = storeToRefs(useUserContext())

  const variable = reactive({ entityId, email: currentUser.email })

  // SECTION Entity
  const { data } = useGetUserQuery(variable, {
    refetchInterval: 15 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    queryClient,
  })

  const entity = computed(() => {
    return data.value?.entity
  })

  // check path urlV3
  /* const { domain, platform } = useEnv()
  watchEffect(() => {
    // localhost on bouge pas
    if (domain !== 'localhost') {
      if (platform === 'K22') { // test si K22
        if (entity.value?.entity.urlV3 && !window.location.href) { // test si urlV3 pas la même,
          return window.location.href = `${entity.value?.entity.urlV3}/${entity.value?.entityId}`
        }
      }
    }
  }) */

  const warehouse = computed(() => {
    return entity.value?.warehouses.find(warehouse => warehouse.warehouseId === currentUser.warehouseId)
  })

  function getWarehouse(warehouseId: string | undefined | null) {
    if (!warehouseId)
      return null
    return entity.value?.warehouses.find(warehouse => warehouse.warehouseId === warehouseId)
  }

  function getPriceList(priceListId: string | undefined | null) {
    if (!priceListId)
      return null
    return entity.value?.priceLists.find(pl => pl.priceListId === priceListId)
  }

  function getSalesPerson(salesPersonId: string | undefined | null) {
    if (!salesPersonId)
      return null
    return entity.value?.salesPersons.find(sp => sp.employeeId === salesPersonId)
  }

  function getSubEntity(id: string | undefined | null) {
    if (!id)
      return null
    return entity.value?.subEntities.find(e => e.id === id)
  }

  // SECTION Suppliers & Customers
  const {
    isLoading: contactsIsLoading,
    data: contactsData,
    refetch: contactsRefetch,
    isRefetching: contactsIsRefetching,
  } = useGetSuppliersAllQuery(variable, {
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryClient,
  })

  // Suppliers ----------------------------------
  const contactsDataIsSupplier = computed(() => {
    if (!contactsData.value?.buyers?.items)
      return []

    return sortBy(contactsData.value?.buyers.items, ['supplier.fullName'])
  })

  const contactsDataIsSupplierCount = computed(() => {
    return contactsData.value?.buyers?.count || 0
  })

  function getSupplier(supplierId: string | undefined | null) {
    if (!supplierId)
      return null
    return contactsDataIsSupplier.value.find(s => s.supplier.supplierId === supplierId)
  }

  // Customers ----------------------------------
  const contactsDataIsCustomer = computed(() => {
    if (!contactsData.value?.customers?.items)
      return []

    return sortBy(contactsData.value?.customers.items, ['supplier.fullName'])
  })

  const contactsDataIsCustomerCount = computed(() => {
    return contactsData.value?.customers?.count || 0
  })

  function getCustomer(supplierId: string | undefined | null) {
    if (!supplierId)
      return null
    return contactsDataIsCustomer.value.find(s => s.supplier.supplierId === supplierId)
  }

  // SECTION Products
  const {
    isLoading: productsIsLoading,
    data: productsData,
    refetch: productsRefetch,
    isRefetching: productsIsRefetching,
  } = useGetProductsAllQuery(variable, {
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryClient,
  })

  // Produuits Achats
  const productsDataBuy = computed(() => {
    if (!productsData.value?.buys?.items)
      return []

    return sortBy(productsData.value?.buys.items, ['productRef'])
  })

  const productsDataBuyCount = computed(() => {
    return productsData.value?.buys?.count || 0
  })

  // Produits Ventes
  const productsDataSell = computed(() => {
    if (!productsData.value?.sells?.items)
      return []

    return sortBy(productsData.value?.sells.items, ['productRef'])
  })

  const productsDataSellCount = computed(() => {
    return productsData.value?.sells?.count || 0
  })

  // SECTION Transaction Balance
  // const variablesFiscalDates = reactive({
  //   entityId,
  //   fiscalDates: getFiscalDates(1),
  // })

  // const {
  //   isLoading: balancesIsLoading,
  //   data: balancesData,
  //   refetch: balancesRefetch,
  //   isRefetching: balancesIsRefetching,
  // } = useGetBalanceSuppliersQuery(variablesFiscalDates, {
  //   staleTime: Number.POSITIVE_INFINITY,
  //   refetchInterval: 24 * 60 * 60 * 1000,
  //   refetchOnWindowFocus: false,
  //   queryClient,
  // })

  // const balancesDataItems = computed(() => {
  //   return balancesData.value?.getBalanceSuppliers?.items || []
  // })

  // const balancesDataCount = computed(() => {
  //   return balancesData.value?.getBalanceSuppliers?.count || 0
  // })

  function getFiscalDates(duringYear: number = 3) {
    let fiscalDates: Array<StartEndDateInput> = []

    if (!data?.value?.entity?.entity)
      return fiscalDates

    for (
      let i = 1,
        len = data.value.entity.entity.endDatesFiscal!.length;
      i < len;
      i++
    ) {
      const startDate = DateTime.fromJSDate(new Date(
        data.value.entity.entity.endDatesFiscal![i - 1],
      ),
      )
        .plus({ days: 1 })
        .toFormat('yyyy-MM-dd')
      fiscalDates.push({
        start: startDate,
        end: data.value.entity.entity.endDatesFiscal![i],
      })
    }

    fiscalDates = fiscalDates.slice(
      duringYear * -1,
    ) // Max 3 years

    return fiscalDates
  }

  // function getBalance(account: string | undefined) {
  //   if (!account)
  //     return
  //   return balancesDataItems.value.find(b => b.account === account)
  // }

  // SECTION ProductInventory

  const variableProductInventory = reactive({ entityId, warehouseId })

  const {
    isLoading: productInventoriesIsLoading,
    data: productInventoriesData,
    refetch: productInventoriesRefetch,
    isRefetching: productInventoriesIsRefetching,
  } = useGetStocksQuery(variableProductInventory, {
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryClient,
  })

  const productInventoriesDataItems = computed(() => {
    return productInventoriesData.value?.stocks?.items || []
  })

  const productInventoriesDataCount = computed(() => {
    return productInventoriesData.value?.stocks?.count || 0
  })

  function getProductInventory(SKU: string | undefined | null) {
    if (!SKU)
      return null
    return productInventoriesDataItems.value.find(pi => pi.SKU === SKU)
  }

  const isLoadingRequiredData = computed(() => {
    return contactsIsLoading.value
      || productsIsLoading.value
    // || balancesIsLoading.value
      || productInventoriesIsLoading.value
  })

  return {
    entity,
    warehouse,
    getWarehouse,
    getPriceList,
    getSalesPerson,
    getSubEntity,
    contactsData,
    contactsRefetch,
    contactsIsLoading,
    contactsIsRefetching,
    getSupplier,
    contactsDataIsSupplier,
    contactsDataIsSupplierCount,
    getCustomer,
    contactsDataIsCustomer,
    contactsDataIsCustomerCount,
    productsIsLoading,
    productsRefetch,
    productsIsRefetching,
    productsData,
    productsDataBuy,
    productsDataBuyCount,
    productsDataSell,
    productsDataSellCount,
    // balancesIsLoading,
    // balancesData,
    // balancesRefetch,
    // balancesIsRefetching,
    // balancesDataItems,
    // balancesDataCount,
    getFiscalDates,
    // getBalance,
    productInventoriesIsLoading,
    productInventoriesData,
    productInventoriesRefetch,
    productInventoriesIsRefetching,
    productInventoriesDataItems,
    productInventoriesDataCount,
    getProductInventory,
    isLoadingRequiredData,
  }
})
